import { Location, isPlatformBrowser, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID, ViewChild, ViewContainerRef } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import browser from 'browser-detect';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment as env } from '../environments/environment';
import { ApplicationService } from './app.service';
import { AppSettingsService, routeAnimations } from './core/core.module';
import { SliderConfiguration } from './core/models/sliderConfiguration.model';
import { GlobalRepositoryService } from './core/repository/global-repository.service';
import { SpinnerService } from './core/spinner/spinner.service';
import { DisclaimerComponent } from './features/disclaimer/disclaimer.component';
import { MenuItem } from './main/models/menuitem';
import { ScreenSizeValue } from './main/models/screenSizeValue';
import { HelperService } from './main/services/helper.service';
import { LoaderService } from './main/services/loader.service';
import { MessageService } from './main/services/message.service';
import { AppConstants } from './shared/constants/app-constants';
import { UserLoginService } from './userLogin.service';
import { LoggingService } from './main/services/logging.service';

@Component({
  selector: 'anms-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit {
  isIframe = false;
  loginDisplay = false;
  userName = '';
  private readonly _destroying$ = new Subject<void>();
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  public formWidth = '0px';
  validLabsite = true;
  errorStatusCode: string = '';
  isDataLoaded = false;
  showCookiePage = false;
  userDetails: any;
  showAccountDetails: boolean = false;
  loadSpinner: boolean = true;
  isLandingPageShow = false;
  labsitePath: string = '';


  private IsSilderReadOnly!: boolean;
  private formChangesubscription!: Subscription;
  private formClosesubscription!: Subscription;
  public isFormOnHold = false;
  public formMode = 'over';
  @ViewChild('dynamic', { static: true, read: ViewContainerRef })
  public viewContainerRef!: ViewContainerRef;
  @ViewChild('sidenav1') public sidenav!: MatSidenav;
  @ViewChild('sidenav2') public sidenav2!: MatSidenav;
  formHoldIconStyle: string = '5%';

  languages = ['en', 'de', 'sk', 'fr', 'es', 'pt-br', 'zh-cn', 'he', 'ro', 'zh'];
  formHoldIconPositions: ScreenSizeValue[] = [{ screenSize: 'xl', value: '5%' }, { screenSize: 'md', value: '10%' },
  { screenSize: 'sm', value: '14%' }, { screenSize: 'xs', value: '23%' }];

  navigation: MenuItem[] = [
    {
      link: 'home',
      label: 'TopBarMenu.Home'
    },
    {
      link: 'labsiteInformation/AboutUsPage',
      label: 'TopBarMenu.AboutUs'
    },
    {
      link: 'labsiteInformation/BeAVolunteerPage',
      label: 'TopBarMenu.BeAVolunteer'
    },
    {
      link: 'activeStudies',
      label: 'TopBarMenu.ActiveStudies'
    },

    {
      link: 'labsiteInformation/ContactPage',
      label: 'TopBarMenu.Contact'
    }
  ];

  profileNavigation: MenuItem[] = [
    {
      link: 'profile/myactivity',
      label: 'Profile.MyActivity'
    },
    {
      link: 'profile',
      label: 'Profile.MyAccount'
    }
  ];

  stickyHeader$: Observable<boolean> | undefined;
  language$: Observable<string> | undefined;
  theme$: Observable<string> | undefined;
  isBrowser: boolean;
  cacheLocation: string;

  constructor(
    private translate: TranslateService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private loginService: UserLoginService,
    public globalRepository: GlobalRepositoryService,
    private loader: LoaderService,
    public router: Router,
    public messageService: MessageService,
    public helperService: HelperService,
    private appService: AppSettingsService,
    public http: HttpClient,
    public dialog: MatDialog,
    public location: Location,
    private metaService: Meta,
    private title: Title,
    public globalRepositoryService: GlobalRepositoryService,
    public applicationService: ApplicationService,
    public spinner: SpinnerService,
    private appSettingService: AppSettingsService,
    public loggingService: LoggingService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.cacheLocation = this.appSettingService.cache.cacheLocation;
  }


  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name || '');
  }

  async addTag(labsiteCode: string) {

    const url = `${this.appService.publicFileUrl}Files/download/` + labsiteCode + '/' + AppConstants.Blob.LabSite + '/' + AppConstants.Blob.Metadata;
    try {
      const result: any = await this.http.get(url).toPromise();
      if (result) {
        this.metaService.addTags(result.MetaTags);
        this.setTitle(result.Title);
      }
    }
    catch (e) {
      if (this.isBrowser) {
        console.error(e);
      }
    }
  }

  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }

  ngOnInit(): void {
    this.setTranslation();
    if (this.isBrowser) {
      this.isIframe = window !== window.parent && !window.opener;
    }
    this.formChangesubscription = this.loader
      .onComponentAdd()
      .subscribe((sliderConfig: SliderConfiguration) => {
        this.displayDrawer(sliderConfig.Width);
        this.IsSilderReadOnly = sliderConfig.IsSilderReadOnly;
      });

    this.formClosesubscription = this.loader
      .onFormClose()
      .subscribe(() => this.closeForm());
    this.loader.setRootViewContainerRef(this.viewContainerRef);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.handleSliderConfirmation();
      });



    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        // clear browser localStorage
        if (this.cacheLocation !== 'localStorage') {
          for (let i = 0; i < window.localStorage.length; i++) {
            const key = window.localStorage.key(i);
            if (key?.indexOf('b2clogin') !== -1) {
              window.localStorage.removeItem(key as string);
            }
          }
        }

        this.loginDisplay =
          this.authService.instance.getAllAccounts().length > 0;

        this.loginService.getUserDetails();
      });
    this.setScreenWiseConfig();
    // @ts-ignore
    const labSiteURLCode = this.router.location._basePath.split('/');
    if (!!labSiteURLCode && labSiteURLCode.length > 0 && labSiteURLCode != '') {
      window.localStorage.setItem('labSiteURLCode', labSiteURLCode[1]);
      // @ts-ignore
      if (this.router.location._locationStrategy._platformLocation._location.pathname.includes('/cookiePolicyPage')) {
        this.loadSpinner = false;
        this.showCookiePage = true;
      }
      else {
        this.loadLabsiteByURLConfigs(labSiteURLCode[1]);
      }
    }
    else {
      if (this.isBrowser && window.location.href.indexOf('logout') == -1 && window.location.href.indexOf('state') == -1) {
        // @ts-ignore   
        const labsiteURLValue = this.router.location._locationStrategy._platformLocation._location.pathname;
        if (labsiteURLValue == '/home' || labsiteURLValue == '/') {
          this.isLandingPageShow = true;
          this.validLabsite = true;
          this.loadSpinner = false;
          this.isDataLoaded = false;
        }
        else {
          this.validLabsite = false;
          this.loadSpinner = false;
          this.errorStatusCode = '404';
          this.isDataLoaded = true;
        }
      }
    }

    this.globalRepositoryService
      .IsVolunteerCodeModified()
      .subscribe((isUpdated) => this.onVolunteerCodeModified(isUpdated));

    this.globalRepositoryService
      .IsVolunteerEmailIdModified()
      .subscribe((isUpdated) => this.onVolunteerEmailIdModified(isUpdated));

    this.globalRepositoryService.canShowVolunteerAppointmentsSubject.subscribe((canShowVolunteerAppointments: boolean) => {
      this.handleMyAppointments(canShowVolunteerAppointments);
    });

    this.globalRepositoryService.canShowStudyProductUsageSubject.subscribe((canShowStudyProductUsage: boolean) => {
      this.handleMyProductUsageLogs(canShowStudyProductUsage);
    });
  }

  onVolunteerEmailIdModified(isUpdated: boolean) {
    if (isUpdated) {
      this.userDetails.email = this.globalRepositoryService.getVolunteerEmailId();
    }
  }

  naviagteToProfile() {
    this.router.navigate(['profile/myactivity']);
  }

  onVolunteerCodeModified(isUpdated: boolean) {
    if (isUpdated) {
      this.getVolunteerDetails();
      const labsiteCode = this.globalRepository.getLabsiteCode();
      const volunteerId = this.globalRepositoryService.getVolunteerId();
      this.canShowProfileTabs(volunteerId, labsiteCode);
    }
  }

  getVolunteerDetails(): any {
    const volunteerId = this.globalRepositoryService.getVolunteerId();

    if (volunteerId !== null && !!volunteerId && volunteerId !== '') {
      this.applicationService.getVolunteerInformation(volunteerId)
        .subscribe((volunteer: any) => {
          this.userDetails = volunteer;
          this.showAccountDetails = true;
          this.spinner.show(false);
        }, () => {
          this.spinner.show(false);
        });
    } else {
      this.spinner.show(false);
    }
  }

  getLabsiteURLConfig(value: string) {
    // @ts-ignore   
    this.router.location._basePath = '/' + value;
    // @ts-ignore   
    this.router.location._locationStrategy._baseHref = '/' + value;
    // @ts-ignore  
    this.router.location._locationStrategy._platformLocation._location.pathname = '/' + value + '/home';
    this.isLandingPageShow = false;
    this.loadLabsiteByURLConfigs(value);
  }
  async loadLabsiteByURLConfigs(labSiteURLCode: string) {

    try {
      if (!this.globalRepositoryService.volunteerEmailId) {
        const requestUrlConfig = `${this.appService.publicFileUrl}Files/download/` + AppConstants.Blob.LabsitesURLConfigFolder + '/' + AppConstants.Blob.LabsitesURLConfigFileName;
        const labsitesUrlConfigs: any = await this.http.get(requestUrlConfig).toPromise();
        const filteredData = labsitesUrlConfigs?.filter((s: any) => s.labSiteURLParam == labSiteURLCode);

        if (filteredData.length > 0) {
          const labsiteCode = filteredData[0].code;
          if (this.appService.isPublicWebSiteSyncronizerEnabled) {
            this.labsitePath = AppConstants.Blob.LabsiteByPublicSynchronizer;
          } else {
            this.labsitePath = AppConstants.Blob.LabSite;
          }
          const requestLabsiteData = `${this.appService.publicFileUrl}Files/download/` + labsiteCode + '/' + AppConstants.Blob.LabSite + '/' + this.labsitePath;
          const labsiteData: any = await this.http.get(requestLabsiteData).toPromise();

          this.doLoadLabsiteByURLConfigs(labsiteData);
        } else {
          this.validLabsite = false;
          this.loadSpinner = false;
          this.errorStatusCode = '404';
          this.isDataLoaded = true;
        }
      } else {
        const url = `${this.appService.baseApiUrlForCore}labsites/GetLabSiteByURLConfigs/` + labSiteURLCode;
        const result: any = await this.http.get(url).toPromise();
        this.doLoadLabsiteByURLConfigs(result);
      }
    } catch (exception: any) {
      this.messageService.alert(this.translate.instant('ErrorWhileLoadingLabSiteDetails'), true, 10000, true, '', exception.message);
    }
  }

  doLoadLabsiteByURLConfigs(labsiteData: any) {
    if (labsiteData) {
      this.addTag(labsiteData.code);
      this.globalRepository.setLabsiteDetails(labsiteData);
      this.globalRepository.setLabsiteCode(labsiteData.code);
      this.globalRepository.setLabsitePrefixCode(labsiteData.prefixCode);
      if (this.isBrowser) {
        window.localStorage.setItem('labSiteCode', labsiteData.code);
        window.localStorage.setItem('labSiteDetails', JSON.stringify(labsiteData));
      }
      this.CanShowNewsLink(labsiteData.isNewsPageUsed);     
      this.LoadLabsiteTextForPublicWebsite(labsiteData.code);
      this.validLabsite = true;
      this.isDataLoaded = true;
    } else if (!labsiteData) {
      this.validLabsite = false;
      this.loadSpinner = false;
      this.errorStatusCode = '404';
      this.isDataLoaded = true;

    } else {
      this.messageService.alert(this.translate.instant('ErrorWhileFetchingLabSiteDetails') + labsiteData.errorMessage, true);
    }
  }

  private async LoadLabsiteTextForPublicWebsite(labsiteCode: string){
    try{
      const requestLabsiteText = `${this.appService.publicFileUrl}Files/download/` + AppConstants.Blob.LabSiteTextFolder + '/' + AppConstants.Blob.LabSiteTextFileName;
      const labsitesTextForLabsite: any = await this.http.get(requestLabsiteText).toPromise();    
      const filteredLabsiteText = labsitesTextForLabsite?.filter((s: any) => s.code == labsiteCode);    
      
      if (filteredLabsiteText.length > 0) { 
       this.globalRepository.setDisclaimersIntroductionText(encodeURIComponent(filteredLabsiteText[0].disclaimersIntroductionText));   
       this.globalRepository.setRegistrationVisitDescriptionText(encodeURIComponent(filteredLabsiteText[0].registrationVisitDescriptionText));    
      } 
    } catch(error){
      this.loggingService.logTrace('Error while fetching the disclaimer and registration visit text from blob');
    }
          
  }
  private CanShowNewsLink(isNewsPageShowed: boolean) {
    if (isNewsPageShowed) {
      this.navigation.splice(4, 0,
        {
          link: 'labsiteInformation/NewsPage',
          label: 'TopBarMenu.News'

        });
    }
  }

  private canShowProfileTabs(volunteerCode: string, labsiteCode: string) {
    this.spinner.show(true);
    this.applicationService.canShowProfileTabs(volunteerCode, labsiteCode)
      .subscribe((canShowProfileTabs: any) => {
        this.globalRepositoryService.setCanShowVolunteerAppointments(canShowProfileTabs?.canShowVolunteerAppointmentTab);
        this.globalRepositoryService.setCanShowStudyProductUsage(canShowProfileTabs?.canShowProductUsageTab);
        this.spinner.show(false);
      }, () => {
        this.spinner.show(false);
      });
  }

  private handleMyAppointments(canShowVolunteerAppointments: boolean) {
    const myAppointmentLinks = 'profile/myappointments';
    const existingItem = this.profileNavigation.find(item => item.link === myAppointmentLinks);
    if (canShowVolunteerAppointments) {
      if (!existingItem) {
        this.profileNavigation.splice(1, 0, {
          link: myAppointmentLinks,
          label: 'Profile.MyAppointments'
        });
      }
    } else {
      const indexToRemove = this.profileNavigation.findIndex(item => item.link === myAppointmentLinks);
      if (indexToRemove !== -1) {
        this.profileNavigation.splice(indexToRemove, 1);
      }
    }
  }

  private handleMyProductUsageLogs(canShowStudyProductUsage: boolean) {
    const myProductUsageLinks = 'profile/myproductusagelog';
    const existingItem = this.profileNavigation.find(item => item.link === myProductUsageLinks);
    if (canShowStudyProductUsage) {
      if (!existingItem) {
        this.profileNavigation.splice(2, 0, {
          link: myProductUsageLinks,
          label: 'Profile.MyProductUsageLog'
        });
      }
    } else {
      const indexToRemove = this.profileNavigation.findIndex(item => item.link === myProductUsageLinks);
      if (indexToRemove !== -1) {
        this.profileNavigation.splice(indexToRemove, 1);
      }
    }
  }

  onLoginClick() {
    this.loginService.onLoginClick();
  }

  onLogoutClick() {
    this.loginService.onLogoutClick();
  }

  onSignUpClick() {
    const dialogRef = this.dialog.open(DisclaimerComponent, {
      data: {
      },
      autoFocus: false,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(popupResponse => {
      if (popupResponse == true) {
        this.loginService.onSignUpClick();
      }
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  setTranslation() {
    this.translate.addLangs(['en', 'fr', 'de', 'es', 'it', 'pl', 'ro', 'zh']);
    const browserLang = this.translate.getBrowserLang();
    if (this.isBrowser) {
      this.translate.use(browserLang.match(/en|fr|de|es|it|pl|ro|zh/) ? browserLang : 'en');
    }
    if (navigator && navigator.language && navigator.language === 'en-US') {
      this.translate.use('en-US');
    }
    this.localeInitializer().then((m) => m)
      .catch(error => {
        console.log(error);
      });
  }

  async localeInitializer(): Promise<any> {
    const browserLang = this.translate.getBrowserLang();
    // For getting only 'en' from 'en-US'
    let localeId = 'en';
    if (this.isBrowser) {
      localeId = browserLang.match(/en|fr|de|es|it|pl|ro|zh/) ? browserLang : 'en';
    }

    // Do not remove the comment, it's called 'magic comment' used for loading less chunks of module
    return await import(
      /* webpackInclude: /(en|fr|de|es|it|pl|ro|zh)\.mjs/ */
      /* webpackChunkName: "i18n-locales" */
      `@/../@angular/common/locales/${localeId}.mjs`
    ).then(module => registerLocaleData(module.default)
    );
  }

  /** Method to show Confirmation Dialog based on Slider Window status after Navigation */
  private handleSliderConfirmation(): void {
    if (this.loader.isSliderEdited() || this.loader.IsDataEdited()) {
      this.messageService.doConfirmationForUnsavedChanges(
        this.processPopupConfirmation
      );
    }
  }

  /** Method to handle the confirmation popup response */
  processPopupConfirmation = (actionResult: boolean): void => {
    if (actionResult) {
      this.loader.cancelForm();
      this.loader.setIsDataEdited(false);
      this.isFormOnHold = false;
    } else {
      this.loader.openSlider();
    }
  }

  /**This method is called on backdrop click of the slider */
  public onBackdropClick(): void {
    this.updateSliderDisplay();
  }

  /**This method is handle Slider Window display When escape key is used on Slider */
  public onEscape(): void {
    this.updateSliderDisplay();
  }

  /* #region Private Methods */
  /** This method is to check if the slider type is Readonly or editable */
  private updateSliderDisplay(): void {
    if (this.IsSilderReadOnly) {
      this.closeForm();
      this.isFormOnHold = false;
    } else {
      if (this.loader.isSliderEdited() || this.loader.IsDataEdited()) {
        this.isFormOnHold = true;
      } else {
        this.closeForm();
        this.isFormOnHold = false;
      }
    }
  }

  /** Method to open Slider Window on Click of form hold icon */
  public onHoldForm(): void {
    this.sidenav2.open();
    this.isFormOnHold = false;
  }

  /** Method to open Slider window */
  private displayDrawer(width: any): void {
    if (width.toString().includes('%')) {
      this.formWidth = width;
    } else {
      this.formWidth = width + 'px';
    }
    this.sidenav2.open();
    this.isFormOnHold = false;
  }

  /** Method to close Slider window */
  private closeForm(): void {
    this.sidenav2.close();
    this.isFormOnHold = false;
  }

  setScreenWiseConfig() {
    this.helperService.GetMediaType();
    this.helperService.getScreenSize().subscribe(x => {
      const style = this.helperService.getScreenSizeValue(this.formHoldIconPositions, x);
      if (style) {
        this.formHoldIconStyle = style;
      }
    });
  }

  getLabsiteCodeByURLConfig() {
    this.spinner.show(true);
  }
}
