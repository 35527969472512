export class FooterInformation {    
    socialMediaLinks!: SocialMediaLinks;
  }

  export class SocialMediaLinks {
    labsiteSocialMediaLinkId!: string;
    labSiteId!: string;
    tiktokUrl!: string;
    facebookUrl!: string;
    instagramUrl!: string;
    youtubeUrl!: string;
    weChatUrl!: string;
}