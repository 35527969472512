import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { GlobalRepositoryService } from '../../../core/repository/global-repository.service';
import { SpinnerService } from '../../../core/spinner/spinner.service';
import { MessageService } from '../../services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { FooterInformation, SocialMediaLinks } from './footer.model';
import { FooterService } from './footer.service';
import { isPlatformBrowser } from '@angular/common';
import { AppSettingsService } from '../../../core/config/app-settings.service';

@Component({
  selector: 'anms-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  contentType: string = 'Footer';
  footerInformation: FooterInformation = new FooterInformation();
  isDataLoaded = false;
  isBrowser: boolean;
  icpNumber: string | undefined;

  constructor(
    public helperService: HelperService,
    public footerService: FooterService,
    public spinner: SpinnerService,
    public globalRepositoryService: GlobalRepositoryService,
    public messageService: MessageService,
    public translateService: TranslateService,
    public appSettingService: AppSettingsService,
    @Inject(PLATFORM_ID) private platformId: string) {
      this.isBrowser = isPlatformBrowser(platformId); }

  ngOnInit(): void {    
    this.getFooterInformation(this.contentType);
    this.icpNumber = this.appSettingService.getICPNumber;
  }

  getFooterInformation(contentType: string) {
    
    const labsiteCode = this.globalRepositoryService.getLabsiteCode();
    if (labsiteCode) {
      this.spinner.show(true);
      if (!this.globalRepositoryService.volunteerEmailId) {
        this.footerService.getFooterInformationFromBlob(this.globalRepositoryService.getLabsiteCode(), contentType).subscribe((response: any) => {
          if (response) {
            const socialMediaLinks: SocialMediaLinks = response.socialMediaLinks;
            this.footerInformation.socialMediaLinks = socialMediaLinks;  
            this.isDataLoaded = true;
            this.spinner.show(false);
          } else {
            this.messageService.alert(this.translateService.instant('Footer.ErrorWhileFetchingFooterInformation'), true);
            this.spinner.show(false);
          }
        }, () => {
          this.messageService.alert(this.translateService.instant('Footer.ErrorWhileFetchingFooterInformation'), true);
          this.spinner.show(false);
        });
      }
      else {
        this.footerService.getFooterInformation(this.globalRepositoryService.getLabsiteCode()).subscribe((response: SocialMediaLinks) => {
          if (response) {
            const socialMediaLinks: SocialMediaLinks = response;
            this.footerInformation.socialMediaLinks = socialMediaLinks;  
            this.isDataLoaded = true;
            this.spinner.show(false);
          } else {
            this.messageService.alert(this.translateService.instant('Footer.ErrorWhileFetchingFooterInformation'), true);
            this.spinner.show(false);
          }
        }, () => {
          this.messageService.alert(this.translateService.instant('Footer.ErrorWhileFetchingFooterInformation'), true);
          this.spinner.show(false);
        });
      }
    }
  }

  NavigateToLabsiteInformation(attribute: string)
  {
    this.helperService.NavigateToLabsiteInformation(attribute);
  }

  public getCurrentYear(): number{
    return new Date().getFullYear();
  }

  public DisplaySocialMediaLinks(): boolean {
    return (!this.footerInformation.socialMediaLinks?.tiktokUrl) && 
      (!this.footerInformation.socialMediaLinks?.facebookUrl) && 
      (!this.footerInformation.socialMediaLinks?.instagramUrl) && 
      (!this.footerInformation.socialMediaLinks?.youtubeUrl) &&
      (!this.footerInformation.socialMediaLinks?.weChatUrl);  
  }

  public DisplayTiktokLink(): boolean {
    return (!this.footerInformation.socialMediaLinks?.tiktokUrl);    
  }

  public DisplayFacebookLink(): boolean {
    return (!this.footerInformation.socialMediaLinks?.facebookUrl);    
  }

  public DisplayInstagramLink(): boolean {
    return (!this.footerInformation.socialMediaLinks?.instagramUrl);    
  }

  public DisplayYoutubeLink(): boolean {
    return (!this.footerInformation.socialMediaLinks?.youtubeUrl);    
  }

  public DisplayWeChatLink(): boolean {
    return (!this.footerInformation.socialMediaLinks?.weChatUrl);    
  }

  public NavigateToUrl(url: string) {
    if (this.isBrowser) {
      window.open(url, "_blank");
    }
  }

  NavigateToStore(platform: string){
    if (platform == "ios") {
      window.open(this.appSettingService.getIosAppPath, "_blank");
    }
    if (platform == "android") {
      window.open(this.appSettingService.getAndroidAppPath, "_blank");
    }
  }

  NavigateToICPDetails(){
      window.open(this.appSettingService.getICPPath, "_blank");   
  }
}