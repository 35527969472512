import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';


@Component({
  selector: 'anms-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingPageComponent implements OnInit {   

  @Output() valueEmitter: EventEmitter<string> = new EventEmitter<string>();

  landingPageImagesData: any = {};
  isLandingPageLoaded = false; 
  constructor(private http: HttpClient,
    private cdr: ChangeDetectorRef,    
    private router: Router
    ) { }

  ngOnInit(): void {
    // @ts-ignore  
    const baseURL = this.router.location._locationStrategy._platformLocation._location.origin;

    this.http.get<any>(baseURL+'/assets/images/landingPageImages/landingPageImagesList.json').subscribe(
      (data) => {              
        this.landingPageImagesData = data;  
        this.sortData(this.landingPageImagesData);
        this.isLandingPageLoaded = true;    
        this.cdr.detectChanges();   
      },
      (error) => {
        console.error('Error fetching location data:', error);
      }
    );
  }  

  sortData(landingPageImagesData: any) {
    landingPageImagesData.sort((a: { labsitename: string; }, b: { labsitename: any; }) => {
      return a.labsitename.localeCompare(b.labsitename);
    });
  }
  redirectToURL(url: string): void {    
    this.valueEmitter.emit(url);   
  }  
}
