export class MatDatePickerConfig {
  startDate!: Date;
  startView!: string;
  maximumDate!: Date | null;
  minimumDate!: Date | null;
  placeholder!: string;
  dateFilter!: Function;
  TodayButtonLabel!: string;
  TodayButtonTooltip!: string;
  DateButtonTooltip!: string;
  ClearButtonTooltip!: string;
  DatePlaceholder!: string;
  dateFilterError!: string;
  dateClass!: Function;
  removeTodaysIcon : boolean = false;
}
