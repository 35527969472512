import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalRepositoryService } from '../../../core/repository/global-repository.service';
import { SpinnerService } from '../../../core/spinner/spinner.service';
import { LabsiteInformation } from '../../../features/labsiteInformation/labsiteInformation.model';
import { LabsiteInformationService } from '../../../features/labsiteInformation/labsiteInformation.service';
import { MessageService } from '../../../main/services/message.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'anms-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  headerText : any = '';
  contentType: string = 'BannerText';
  labsiteInformation !: LabsiteInformation;

  constructor(
    public translate: TranslateService,
    public labsiteInformationService: LabsiteInformationService,
    public spinner: SpinnerService,
    public globalRepositoryService: GlobalRepositoryService,
    public messageService: MessageService,
    public translateService: TranslateService,
    public sanitized: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getLabsiteInformation(this.contentType);
  }

  getLabsiteInformation(contentType: string) {
    
    const labsiteCode = this.globalRepositoryService.getLabsiteCode();
    if (labsiteCode) {
      this.spinner.show(true);
      if (!this.globalRepositoryService.volunteerEmailId) {
        this.labsiteInformationService.getLabsiteInformationFromBlob(this.globalRepositoryService.getLabsiteCode(), contentType).subscribe((response: any) => {
          if (response) {
            const labsiteInfo: LabsiteInformation = { hasError: false, errorMessage: false, content: response.content};

            this.labsiteInformation = labsiteInfo;
            this.headerText = this.sanitized.bypassSecurityTrustHtml(this.labsiteInformation.content);
            this.spinner.show(false);
          } else {
            this.messageService.alert(this.translateService.instant('LabsiteInformation.ErrorWhileFetchingLabsiteInformation'), true);
            this.spinner.show(false);
          }
        }, () => {
          this.messageService.alert(this.translateService.instant('LabsiteInformation.ErrorWhileFetchingLabsiteInformation'), true);
          this.spinner.show(false);
        });
      }
      else {
        this.labsiteInformationService.getLabsiteInformation(this.globalRepositoryService.getLabsiteCode(), contentType).subscribe((response: LabsiteInformation) => {
          if (!response.hasError) {
            this.labsiteInformation = response;
            this.headerText = this.sanitized.bypassSecurityTrustHtml(this.labsiteInformation.content);
            this.spinner.show(false);
          } else {
            this.messageService.alert(this.translateService.instant('TopBarMenu.ErrorWhileFetchingBanner'), true);
            this.spinner.show(false);
          }
        }, () => {
          this.messageService.alert(this.translateService.instant('TopBarMenu.ErrorWhileFetchingBanner'), true);
          this.spinner.show(false);
        });
      }
    }
  }

  transformToCamelCase(value:string): string {
    if (value) {
      const first = value.substring(0, 1).toLowerCase();
      value = first + value.substring(1); 
    }
    return value;
  }
  
}
