import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { GlobalRepositoryService } from '../../core/repository/global-repository.service';
import { HelperService } from '../../main/services/helper.service';
import { Disclaimer } from './disclaimer.model';

@Component({
  selector: 'anms-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit{
  labsiteName: string = '';
  disclaimersIntroductionText: any = '';
  disclaimerData : Disclaimer = new Disclaimer();  
  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<DisclaimerComponent>,
    public helperService: HelperService,
    public globalRepositoryService: GlobalRepositoryService
  ) { }

  ngOnInit(): void {
    this.labsiteName = this.globalRepositoryService.getLabsiteName();
    this.disclaimersIntroductionText = decodeURIComponent(this.globalRepositoryService.getDisclaimersIntroductionText());   
  }

  CanConfirm(){
    return this.disclaimerData.termsOfUse && this.disclaimerData.dataPrivacy && this.disclaimerData.volunteerRules;
  }

  /* #region Events and Methods */
  /** This method is to close the pop up*/
  public Dismiss(): void {
    this.dialogRef.close(false);
  }

  /** This method is to close the pop up and to pass confirmation action */
  public Confirm(): void {
    this.dialogRef.close(true);
  }

  NavigateToLabsiteInformation(attribute: string)
  {
    this.helperService.NavigateToLabsiteInformation(attribute);
  }
}
