import { NgModule, APP_INITIALIZER } from '@angular/core';
import { IPublicClientApplication, PublicClientApplication, LogLevel } from '@azure/msal-browser';
import {
  MsalGuard, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration,
  MsalInterceptor,
  ProtectedResourceScopes
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import  {ConfigService}  from './core/config/config.service';

const URLs = [
  "VolunteerServiceUrl",
  "RecruitmentServiceUrl",
  "SurveyServiceUrl",
  "CoreServiceUrl",
  "QueryServiceUrl",
  "VisitServiceUrl"
]

const API = '/api/';

export function initializerFactory(env: ConfigService): any {
  const promise = env.getSettings().then(() => {
    console.log('finished getting configurations dynamically.');
  });
  return () => promise;
}

// const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(config: ConfigService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: config.getSettingsForKey('auth'),
    cache: config.getSettingsForKey('cache'),
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(config: ConfigService): MsalInterceptorConfiguration {
  let protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();
  const apiScope = config.getSettingsForKey('interceptor').apiAccessScope;

  // Must be added at the end.
  protectedResourceMap = ConfigureProtectedResource(protectedResourceMap, config, apiScope);

  return {
    interactionType: config.getSettingsForKey('interceptor').interactionType,
    protectedResourceMap
  };
}

function ConfigureProtectedResource(protectedResourceMap: Map<string, (string | ProtectedResourceScopes)[] | null>, config: ConfigService, apiScope: any) {
  // Add scope to configure protected resource
  URLs.forEach(key => {
    protectedResourceMap.set(getUrlForKey(key, config), apiScope);
  });

  return protectedResourceMap;
}

function getUrlForKey(key: string, config: ConfigService): string {
  return config.getSettingsForKey('ConfigSettings.' + key) + API;
}


export function MSALGuardConfigFactory(config: ConfigService): MsalGuardConfiguration {
  return {
    interactionType: config.getSettingsForKey('interactionType'),
    authRequest: config.getSettingsForKey('authRequest'),
    loginFailedRoute: config.getSettingsForKey('loginFailedRoute')
  };
}

@NgModule({
  providers: [],
  imports: [MsalModule]
})
export class MsalConfigDynamicModule {

  static forRoot() {
    return {
      ngModule: MsalConfigDynamicModule,
      providers: [
        ConfigService,
        {
          provide: APP_INITIALIZER, useFactory: initializerFactory,
          deps: [ConfigService], multi: true
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [ConfigService]
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
          deps: [ConfigService]
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [ConfigService]
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        }
      ]
    };
  }
}
