import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettingsService } from './core/core.module';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {

    constructor(private http: HttpClient, private appService: AppSettingsService) { }



    public getUserInformation(accountId: string, labsiteCode: string) {
        const url = `${this.appService.baseApiUrlForCore}externalUsers/` + accountId + '/' + labsiteCode;
        return this.http.get(url);
    }

    public getVolunteerInformation(volunteerId: string) {        
        const reqbody = { VolunteerId: volunteerId };
        return this.http.post(this.appService.volunteerUrl + 'information', reqbody);
    }

    public addVolunteer(reqbody: any) {
        return this.http.post(this.appService.volunteerUrl + 'externalUsers', reqbody);
    }

    public addexternalUser(reqbody: any) {
        return this.http.post(this.appService.baseApiUrlForCore + 'externalUsers', reqbody);
    }

    public updateExternalUser(reqbody: any) {
        return this.http.put(this.appService.baseApiUrlForCore + 'externalUsers', reqbody);
    }

    public canShowProfileTabs(volunteerId: string, labsiteCode: string): Observable<any> {
        const reqbody = {
            VolunteerId: volunteerId,
            LabsiteCode: labsiteCode
          };
        return this.http.post<boolean>(this.appService.queryUrl + 'visits/canShowProfileTabs', reqbody);
    }
}
