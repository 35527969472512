import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { ApplicationInsightsService } from "../../main/services/applicationInsights.service";

@Injectable()

export class GlobalErrorHandler extends ErrorHandler {

    constructor(private injector: Injector) {
        super();
    }

    handleError(error: Error) {
        const chunkFailedMessage = "chunkloaderror: loading chunk";
        if (error.message.toLowerCase().includes(chunkFailedMessage)) {
            this.injector.get<ApplicationInsightsService>(ApplicationInsightsService).logException(error);
            this.reloadWindow();
        }
        this.injector.get<ApplicationInsightsService>(ApplicationInsightsService).logException(error);
        super.handleError(error);
    }

    public reloadWindow() {
        window.location.reload();
    }

}