<div style="float: right;">
  <button mat-icon-button color="accent" (click)="dismiss()">
    <span class="icon-close"></span>
  </button>
</div>
<div class="dialog-container">
  <h5>{{ data.title | translate}}</h5>

  <div class="dialog-content" >{{ data.message | translate}}</div>
  <div class="btn-align">
    <button fxFlex="32" fxFlex.md="32" fxFlex.sm="35" fxFlex.xs="35"  mat-button color="primary" class="confirm-btn confirm-text"
      mat-raised-button (click)="confirmed()">{{ data.confirmLable | translate}}</button>
    <button fxFlex="32" fxFlex.md="32" fxFlex.sm="32" fxFlex.xs="32" mat-button mat-stroked-button (click)="dismiss()" color="primary">{{ data.cancelLabel | translate }}</button>
  </div>
</div>
