import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
/** Service to access App Settings based on configuration */
export class AppSettingsService {

    constructor(private configService: ConfigService) {
     }

    private apiString = '/api/';
    /* #region Provate  Methods */

    private serviceConfigUrl(key: string): string {
        return key + this.apiString;
    }

/* #endregion */



    /* #region Public Methods */
    /** Property to get base api url from config */
    public get volunteerUrl(): string {
      const settings: any = this.configService.getConfig('ConfigSettings');
        return this.serviceConfigUrl(settings.VolunteerServiceUrl);
    }

    public get fileManagementUrl(): string {
      const settings: any = this.configService.getConfig('ConfigSettings');
      return this.serviceConfigUrl(settings.FileManagementServiceUrl);
    }

    public get baseApiUrlForCore(): string {
       const settings: any = this.configService.getConfig('ConfigSettings');
      return this.serviceConfigUrl(settings.CoreServiceUrl);
    }

    public get isPublicWebSiteSyncronizerEnabled(): string {
      const settings: any = this.configService.getConfig('ConfigSettings');
     return settings.IsPublicWebSiteSyncronizerEnabled;
   }

    public get recruitmentUrl(): string {
      const settings: any = this.configService.getConfig('ConfigSettings');
      return this.serviceConfigUrl(settings.RecruitmentServiceUrl);
    }

    public get surveyUrl(): string {
      const settings: any = this.configService.getConfig('ConfigSettings');
      return this.serviceConfigUrl(settings.SurveyServiceUrl);
    }

    public get queryUrl(): string {
      const settings: any = this.configService.getConfig('ConfigSettings');
      return this.serviceConfigUrl(settings.QueryServiceUrl);    }

    public get visitUrl(): string {
      const settings: any = this.configService.getConfig('ConfigSettings');
      return this.serviceConfigUrl(settings.VisitServiceUrl);    }

    public get publicFileUrl(): string {
      const settings: any = this.configService.getConfig('ConfigSettings');
      return this.serviceConfigUrl(settings.PublicFileManagementServiceUrl);
    }

  /** Property to get environment name from config */
  public get environmentName(): string {
    return this.configService.getConfig('environmentName');
  }

  public get msalConfig(): any {
    return this.configService.getConfig('auth');
  }



  /** Property to get version from config */
  public get version(): string {
    return this.configService.getConfig('version');
  }

  public get recruitmentFileLocation(): string {
    return this.configService.getSettingsForKey('FileManagementPublicFile').Recruitment;
  }

  public get homepageFileLocation(): string {
    return this.configService.getSettingsForKey('FileManagementPublicFile').HomePage;
  }

  public get appInsightsConfig(): any {
    return this.configService.getConfig('appInsights');
  }  

  public get cache(): any {
    return this.configService.getConfig('cache');
  }

  public get getIosAppPath(): string {
    return this.configService.getSettingsForKey('iosAppPath');
  }

  public get getAndroidAppPath(): string {
    return this.configService.getSettingsForKey('androidAppPath');
  }
  
  public get getICPNumber(): string {
    return this.configService.getSettingsForKey('ICPNumber');
  }

  public get getICPPath(): string {
    return this.configService.getSettingsForKey('ICPPath');
  }
}
