import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'anms-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent implements OnInit {

  showSpinner = false;

  constructor(public spinnerService: SpinnerService, private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.spinnerService.getMessage().subscribe((status: boolean) => {
      this.SetSpinner(status);
    });
  }

  SetSpinner(status: boolean) {
    this.showSpinner = status;
    this.cdRef.detectChanges();
  }
}