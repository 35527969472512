import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
/** Service to handle Spinner or page/data loading in application */
export class SpinnerService {

  /* #region Fields */
  private subject: Subject<boolean> = new Subject<boolean>();
  /* #endregion */

  /* #region Public Methods */
  /** Method to control/toggle spinner display */
  public show(show: boolean): void {
    this.subject.next(show);
  }

  /** Method to subscribe to spinner service to display spinner by status */
  public getMessage(): Observable<boolean> {
    return this.subject.asObservable();
  }
  /* #endregion */
}
