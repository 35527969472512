import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { GlobalErrorComponent } from './shared/global-error/global-error.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'activeStudies',
    loadChildren: () =>
      import('./features/active-studies/active-studies.module').then(
        (m) => m.ActiveStudiesModule
      )
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./features/profile/profile.module').then((m) => m.ProfileModule),
      canActivate: [MsalGuard]
  },
  {
    path: 'studyDetail',
    loadChildren: () =>
      import('./features/study-detail/study-detail.module').then(
        (m) => m.StudyDetailComponentModule
      )
  },
  {
    path: 'labsiteInformation/:contentType',
    loadChildren: () =>
      import('./features/labsiteInformation/labsiteInformation.module').then(
        (m) => m.LabsiteInformationComponentModule
      )
  },
  {
    path: '**',
   component: GlobalErrorComponent
  },
  // {
  //   path: "**",
  //   redirectTo: ""
  //  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabledBlocking' //Modifed from enabled to enabledBlocking as this is not allowed field in angular
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
