<div class="footer footer-divider">
  
  <div *ngIf="isDataLoaded && !DisplaySocialMediaLinks()" fxLayout="row" fxLayout.xs="row" class="social-media-links-div">
    
    <mat-card class="social-media-links-mat-card">
      <mat-card-content>
          <span class="follow-us-text">{{'Footer.FollowUs' | translate}}</span> <span class="footer-link-divider"></span>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="!DisplayFacebookLink()" class="social-media-links-mat-card" (click)="NavigateToUrl(footerInformation.socialMediaLinks.facebookUrl)">
      <mat-card-content>
        <span class="icon-facebook-f link"></span>
        <span class="footer-follosUs-divider"></span>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="!DisplayYoutubeLink()" class="social-media-links-mat-card" (click)="NavigateToUrl(footerInformation.socialMediaLinks.youtubeUrl)">
      <mat-card-content>
        <span class="icon-youtube link"></span>
        <span class="footer-follosUs-divider"></span>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="!DisplayInstagramLink()" class="social-media-links-mat-card" (click)="NavigateToUrl(footerInformation.socialMediaLinks.instagramUrl)">
      <mat-card-content>
        <span class="icon-instagram link"></span>
        <span class="footer-follosUs-divider"></span>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="!DisplayTiktokLink()" class="social-media-links-mat-card" (click)="NavigateToUrl(footerInformation.socialMediaLinks.tiktokUrl)">
      <mat-card-content>
        <span class="icon-tiktok link"></span>
        <span class="footer-follosUs-divider"></span>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="!DisplayWeChatLink()" class="social-media-links-mat-card" (click)="NavigateToUrl(footerInformation.socialMediaLinks.weChatUrl)">
      <mat-card-content>
        <span class="icon-weChat link"></span>
        <span class="footer-follosUs-divider"></span>
      </mat-card-content>
    </mat-card>

  </div>

  <div fxLayout="row"  class="applink" *ngIf="appSettingService.getAndroidAppPath || appSettingService.getIosAppPath">
    <img src="../../../assets/android.png" (click)="NavigateToStore('android')" *ngIf="appSettingService.getAndroidAppPath" > &nbsp; &nbsp; &nbsp;
    <img src="../../../assets/ios.png" (click)="NavigateToStore('ios')" *ngIf="appSettingService.getIosAppPath">
  </div>

  <br/>
    <div  fxLayout="row" fxLayout.xs="row" style="justify-content: center;">
      <mat-card style="box-shadow: none;" (click)="NavigateToLabsiteInformation('DataPrivacyPage')">
        <mat-card-content>
            <span class="footer-menu-text">{{'Footer.PrivacyPolicyText' | translate}}</span> <span class="footer-link-divider">|</span>
        </mat-card-content>
      </mat-card>
      <mat-card style="box-shadow: none;" (click)="NavigateToLabsiteInformation('TermsOfUse')">
        <mat-card-content>
            <span class="footer-menu-text">{{'Footer.TermsOfUse' | translate}}</span><span class="footer-link-divider">|</span>
        </mat-card-content>
      </mat-card>
      <mat-card style="box-shadow: none;" (click)="NavigateToLabsiteInformation('ContactPage')">
        <mat-card-content>
            <span class="footer-menu-text">{{'Footer.Contact' | translate}}</span>
        </mat-card-content>
      </mat-card>  
      <mat-card   *ngIf="appSettingService.getICPNumber" style="box-shadow: none;" (click)="NavigateToICPDetails()">
        <mat-card-content>
          <span class="footer-link-divider-For-ICP">|</span> <span class="footer-menu-text">{{icpNumber}}</span>
        </mat-card-content>
      </mat-card>           
    </div>
    <div fxLayout="row" class="footer-logo">
      <img src="../../../assets/footer-logo.png">
    </div>
    <div fxLayout="row" class="footer-text">
      <span>{{'Footer.CopyRightText' | translate}} {{getCurrentYear()}}</span>
    </div>
    &nbsp;
</div>