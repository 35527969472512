import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { AppSettingsService } from '../../core/config/app-settings.service';
import { LoaderService } from '../../main/services/loader.service';
import { PrintVolunteerCardRequestDto } from './models/PrintVolunteerCard.model';

@Injectable({
    providedIn: 'root'
})
export class VolunteerService {
    volunteerAttributesTab = 0;
    isVolunteerBasicInfoUpdated = new Subject<boolean>();

    constructor(public http: HttpClient, private appService: AppSettingsService,
        private componentFactoryResolver: ComponentFactoryResolver,
        public loader: LoaderService) {
    }

    public OnVolunteerBasicInfoUpdated(): Observable<boolean> {
        return this.isVolunteerBasicInfoUpdated.asObservable();
    }

    public AddVolunteer(reqbody: any) {
        const headers = new HttpHeaders().set('content-type', 'application/json').set('LabSiteCode', JSON.parse(reqbody).LabSiteCode);
        return this.http.post(this.appService.volunteerUrl + 'volunteers', reqbody, { 'headers': headers });
    }
    public DeleteVounteer(volunteerId: string) {
        const reqbody = {
            VolunteerId: volunteerId,
        };
        return this.http.put(this.appService.volunteerUrl + 'volunteers/DeleteVolunteer', reqbody);
    }

    UpdateBasicVolunteerInfo(reqbody: string) {
        const headers = new HttpHeaders().set('content-type', 'application/json');
        return this.http.put(this.appService.volunteerUrl + 'volunteers/UpdateBasicInfo', reqbody, { 'headers': headers });
    }

    public AddVolunteerSlider(isRegistration: boolean): void {
        import('./maintenance/maintenance.module')
            .then(mod => mod.MaintenanceModule)
            .then(lazyModule => {
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(lazyModule.components['lazy']);
                this.loader.addDynamicComponent(componentFactory, '100%', { isRegistrationFlow: isRegistration });
            });
    }

    public EditBasicInformationSlider(data: any, sliderWidth: string): void {
        import('./volunteerUpdate/volunteerUpdate.module')
            .then(mod => mod.VolunteerUpdateModule)
            .then(lazyModule => {
                const componentFactory = this.componentFactoryResolver.resolveComponentFactory(lazyModule.components['lazy']);
                this.loader.addDynamicComponent(componentFactory, sliderWidth, data);
            });
    }

    public GetEnum(enumType: string) {
        const url = `${this.appService.volunteerUrl}enums/GetEnumData/` + enumType;
        return this.http.get(url);
    }

    public LoadVolunteerProfileEnums() {
        const techniqueEnums = this.GetEnum('TechniqueEnums')
        const educationDegreeEnums = this.GetEnum('EducationDegreeEnums');
        const healthInsuranceEnums = this.GetEnum('HealthInsuranceEnums');

        return forkJoin([techniqueEnums, educationDegreeEnums, healthInsuranceEnums
        ]);
    }

    public ValidateIBANAndBIC(reqbody: any) {
        return this.http.post(this.appService.volunteerUrl + 'volunteerFinance/ValidateIBANAndBIC', reqbody);
    }

    public GetAdvertisementMedias(labsiteId: any) {
        const url = `${this.appService.baseApiUrlForCore}advertisementMedias/advertisementMediasForLabsite/` + labsiteId;
        return this.http.get(url);
    }

    public PrintVolunteerCard(printVolunteerCardRequestDto: PrintVolunteerCardRequestDto): Observable<any> {
        const params = new HttpParams()
            .set('volunteerId', printVolunteerCardRequestDto.VolunteerId)
            .set('labsiteCode', printVolunteerCardRequestDto.LabsiteCode);

        const options = {
            params,
            responseType: 'blob' as 'json'
        };
        return this.http.get(this.appService.volunteerUrl + 'volunteers/PrintVolunteerIdCard/', options)
    }

}
