<anms-spinner *ngIf="loadSpinner"></anms-spinner>

<div class="theme-wrapper" [hidden]="!(validLabsite && isDataLoaded)">
  <div *ngIf="isDataLoaded">
    <anms-toolbar></anms-toolbar>
  </div>

  <mat-sidenav-container id="mat-sidenav-container-root" (backdropClick)="onBackdropClick()">

    <mat-sidenav #sidenav1 (keydown.escape)="onEscape()" mode="push">
      <object style="pointer-events: none;" data="assets/images/logo.svg" type="image/svg+xml" height="25">
        Eurofins Logo
      </object>
      <mat-nav-list>
        <a mat-list-item *ngFor="let item of navigation" (click)="sidenav.close()" [routerLink]="[item.link]"
          routerLinkActive="active">
          {{item.label | translate}}
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav #sidenav2 (keydown.escape)="onEscape()" [style.width]="formWidth" class="mat-elevation-z10"
      [position]="'end'" [disableClose]="false" [autoFocus]="false" [mode]="$any(formMode)">
      <div class="app-sidenav-content">
        <ng-template #dynamic></ng-template>
      </div>
    </mat-sidenav>


    <div class="wrapper">

      <div class="toolbar" [style.position]="'true'" [class.mat-elevation-z4]="true">
        <mat-toolbar>
          <button mat-icon-button fxShow="true" fxHide.gt-sm="true" (click)="sidenav.open()">
            <span class="icon-list"></span>

          </button>

          <object style="pointer-events: none;" data="assets/images/logo.svg" type="image/svg+xml" height="25">
            Eurofins Logo
          </object>

          <span class="spacer"></span>


          <button mat-button class="nav-button" *ngFor="let item of navigation" [routerLink]="[item.link]"
            routerLinkActive="active" fxShow="true" fxHide.lt-md="true">
            <span style="color: #2B3A67">{{item.label | translate}}</span>

          </button>


          <button mat-stroked-button color="primary" *ngIf="!loginDisplay" (click)="onLoginClick()">
            {{ 'TopBarMenu.SignIn' | translate }}
          </button>
          
          <button *ngIf="loginDisplay" mat-button [matMenuTriggerFor]="toolbarUserMenu" class="profile-button">
            <span class="icon-user"></span><span class="profile-text" fxShow="true" fxHide.lt-md="true">{{ 'Profile.ProfileHeader' | translate}}</span> 
          </button>

          <mat-menu #toolbarUserMenu="matMenu">
            <div class="ProfileInfo" *ngIf="showAccountDetails">

              <div class="profile-div" (click)="naviagteToProfile()">
                <div mat-card-avatar class="icon-user profileheader-image"></div>
                <mat-label class="wordWrap profile-username" matTooltip="{{userDetails.name}}" matTooltipPosition="above"><b>{{userDetails.name}}</b></mat-label> 
              </div>
              <div class="profile-div" (click)="naviagteToProfile()">                
                <mat-label class="wordWrap profile-email" matTooltip="{{userDetails.email}}" matTooltipPosition="above">{{userDetails.email}}</mat-label><br>                
              </div>
              <mat-divider></mat-divider>

              <button mat-menu-item *ngFor="let item of profileNavigation" [routerLink]="[item.link]">
                <span> {{item.label | translate}}</span>
              </button>
              <mat-divider></mat-divider>

              <button mat-menu-item (click)="onLogoutClick()">
                <span>{{ 'Profile.SignOut' | translate }}</span>
              </button>
            </div>
          </mat-menu>

          <button mat-raised-button color="primary" *ngIf="!loginDisplay" [fxShow.sm]="false" [fxShow.xs]="false"
            (click)="onSignUpClick()">
            {{ 'TopBarMenu.RegisterAsVolunteer' | translate }}
          </button>

        </mat-toolbar>

      </div>

      <div *ngIf="isDataLoaded" class="content"
        [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig?.data?.title">

        <router-outlet #o="outlet"></router-outlet>
      </div>
      <div *ngIf="isDataLoaded">
        <anms-footer class="footer-divider"></anms-footer>
      </div>
    </div>

    <div>
      <button *ngIf="isFormOnHold" style="float: right;" (click)="onHoldForm()" color="warn" class="form-on-hold-icon"
        [ngStyle]="{'margin-top': formHoldIconStyle}" mat-mini-fab>
        <span class="icon-chevron-left"></span>
      </button>
    </div>
  </mat-sidenav-container>
</div>
<div class="theme-wrapper" *ngIf="!(validLabsite || isLandingPageShow)">
  <anms-global-error [errorStatusCode]="errorStatusCode" [isNavigateBackRequired]="false">
  </anms-global-error>
</div>

<div *ngIf="isLandingPageShow"> 
  <div class="toolbar" [style.position]="'true'" [class.mat-elevation-z4]="true">
    <mat-toolbar> 
      <object style="pointer-events: none;" data="assets/images/logo.svg" type="image/svg+xml" height="25">
        Eurofins Logo
      </object>
      <span class="spacer"></span> 
    </mat-toolbar>
  </div>
  <anms-landing-page (valueEmitter)="getLabsiteURLConfig($event)" > </anms-landing-page> 
</div>

<anms-cookie [isShown]="showCookiePage"></anms-cookie>