import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import {
  HttpClientModule, HTTP_INTERCEPTORS
} from '@angular/common/http'
import { FormsModule } from '@angular/forms';
import {
  ROUTE_ANIMATIONS_ELEMENTS,
  routeAnimations
} from './animations/route.animations';
import { AnimationsService } from './animations/animations.service';
import { AppSettingsService } from './config/app-settings.service';
import { ConfigService } from './config/config.service';
import { SpinnerService } from './spinner/spinner.service';
import { SpinnerComponent } from './spinner/spinner.component';
import { HttpInterceptorService } from './http-interceptors/http-interceptor.service';
import { GlobalErrorHandler } from './handlers/global-error-handler';
import { HttpErrorInterceptor } from './http-interceptors/http-error.interceptor';

export {
  routeAnimations,
  ROUTE_ANIMATIONS_ELEMENTS,
  AnimationsService,
  AppSettingsService,
  ConfigService,
  SpinnerComponent
};

export function load_config(configService: ConfigService) {
  return () => configService.getSettings();
}

@NgModule({
  imports: [
    // angular
    CommonModule,
    HttpClientModule,
    FormsModule
  ],
  declarations: [
    SpinnerComponent
  ],
  providers: [SpinnerService,
    { provide: APP_INITIALIZER, useFactory: load_config, deps: [ConfigService], multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler},
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  exports: [
    // angular
    FormsModule,
    SpinnerComponent
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
