import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'anms-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent {

  constructor(private dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public translate: TranslateService) { }

  /* #region Events and Methods */
  /** This method is to close the pop up*/
  public dismiss(): void {
    this.dialogRef.close(false);
  }

  /** This method is to close the pop up and to pass confirmation action */
  public confirmed(): void {
    this.dialogRef.close(true);
  }
  /* #endregion */
}
