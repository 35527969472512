import { ComponentFactory, Injectable, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MessageService } from './message.service';
import { DrawerConfiguration } from '../../core/models/drawerConfiguration.model';
import { SliderConfiguration } from '../../core/models/sliderConfiguration.model';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  /* #region Fields */
  private componentFactory: any = null;
  private rootViewContainer!: ViewContainerRef;
  private subject = new Subject<any>();
  private subjectClose = new Subject<boolean>();
  private sliderConfig: SliderConfiguration = new SliderConfiguration();
  private isDataEdited = false;
  /* #endregion */

  constructor(private messageService: MessageService) { }

  /* #region Public Methods */
  /** Method to subscrible to loader when component is added in side nav*/
  public onComponentAdd(): Observable<SliderConfiguration> {
    return this.subject.asObservable();
  }

  /** Method to subscribe to closing of component or form */
  public onFormClose(): Observable<boolean> {
    return this.subjectClose.asObservable();

  }

  /** Method to log an entry in console */
  public setRootViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.rootViewContainer = viewContainerRef;
  }

  /**This method is to check if the slider is already open and if so, shows pop-up */
  public addDynamicComponent(factory: ComponentFactory<DrawerConfiguration>, width: any, data: any, isReadOnly: boolean = false): void {
    if (this.componentFactory === null) {
      this.createSliderComponent(factory, width, data, isReadOnly);
    } else {
      if (this.componentFactory.instance.isSliderDataEdited || this.isDataEdited) {
        this.messageService.doConfirmationForUnsavedChanges(this.loadSlider,
          {
            sliderConfig: this.sliderConfig,
            factory: factory, width: width,
            data: data, isReadOnly: isReadOnly
          });
      } else {
        this.createSliderComponent(factory, width, data, isReadOnly);
      }
    }
  }

  /** Mathod to handle the confirmation popup response for the already open slider */
  loadSlider = (actionResult: boolean, sliderInfo: any): void => {
    if (!actionResult) {
      this.subject.next(sliderInfo.sliderConfig);
    } else {
      this.cancelForm();
      this.createSliderComponent(sliderInfo.factory, sliderInfo.width,
        sliderInfo.data, sliderInfo.isReadOnly);
    }
  }

  /** Method to Save form */
  public closeForm(): void {
    this.subjectClose.next(true);
    this.componentFactory.instance.isSliderDataEdited = false;
    this.componentFactory.destroy();
    this.setIsDataEdited(false);
  }

  /** Method to cancel the form */
  public cancelForm(): void {
    this.subjectClose.next(false);
    this.componentFactory.instance.isSliderDataEdited = false;
    this.componentFactory.destroy();
    this.setIsDataEdited(false);
  }

  /**This method is to check if the slider data is edited */
  public isSliderEdited(): boolean {
    if (this.componentFactory !== null) {
      return this.componentFactory.instance.isSliderDataEdited;
    }
    return false;
  }

  public setIsDataEdited(value: boolean) {
    this.isDataEdited = value;
  }

  public IsDataEdited(): boolean {
    return this.isDataEdited;
  }

  /**This method is used to open the on hold slider when there is an action on confirmation pop-up */
  public openSlider(): void {
    this.subject.next(this.sliderConfig);
  }

  /* #endregion */

  /* #region Private Methods */
  /**This method is to create new view on slider component */
  private createSliderComponent(factory: ComponentFactory<DrawerConfiguration>, width: any, data: any, isReadOnly: boolean): void {
    this.rootViewContainer.clear();
    this.componentFactory = factory.create(this.rootViewContainer.injector);
    this.rootViewContainer.insert(this.componentFactory.hostView);
    (<DrawerConfiguration>this.componentFactory.instance).sliderFormData = data;
    this.sliderConfig = { Width: width, IsSilderReadOnly: isReadOnly };
    this.componentFactory.instance.isSliderDataEdited = false;
    this.setIsDataEdited(false);
    this.subject.next(this.sliderConfig);
  }
  /* #endregion */
}
