import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'anms-global-error',
  templateUrl: './global-error.component.html',
  styleUrls: ['./global-error.component.scss']
})
export class GlobalErrorComponent implements OnInit {

  /* #region Fields */
  @Input() public errorStatusCode : string = '404';
  @Input() public isNavigateBackRequired = true;
  @Output() public navigatedBack: EventEmitter<void> = new EventEmitter<void>();
  public errorTitle !: string;
  public errorMessage !: string;
  public errorIcon !: string;
  /* #endregion */

  constructor() { }

  /* #region Events and Methods */
  public ngOnInit(): void {
    this.loadErrorDetailsByStatusCode();
  }

  /** This method is used to navigate the user to previouse page */
  public navigateBack(): void {
    this.navigatedBack.emit();
  }
  /* #endregion */

  /* #region Private Methods */
  /** This method is being used to load error details by error status code */
  private loadErrorDetailsByStatusCode(): void {
    switch (this.errorStatusCode) {
    
      case '401':
        this.errorIcon = 'icon-Unauthorized';
        break;
      case '403':
        this.errorIcon = 'icon-Forbidden';
        break;
      case '404':
        this.errorIcon = 'icon-caution';
        break;
      default:
        this.errorStatusCode = '500';
        this.errorIcon = 'icon-Internal-Server-Error';
        break;
    }
  }
  /* #endregion */

}
