import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AppSettingsService } from '../../core/core.module';
import { AppConstants } from '../../shared/constants/app-constants';

@Injectable({
    providedIn: 'root'
})
export class LabsiteInformationService {    

    constructor(public http: HttpClient, public appService: AppSettingsService) { }

    public getLabsiteInformation(labsiteCode: string, contentType: string): Observable<any> {
       // labsiteCode = 'EUITRO';
        return this.http.get(this.appService.baseApiUrlForCore + 'labsites/GetInformationByAttribute/' + labsiteCode+ '/'+ contentType);
    }

    public getLabsiteInformationFromBlob(labsiteCode: string, infoType:string): Observable<any> {        
        let urlParams: string =  labsiteCode + '/' + AppConstants.Blob.LabSite + '/' + AppConstants.Blob.LabSite+ '/' + infoType;
        if(this.appService.isPublicWebSiteSyncronizerEnabled){
            urlParams = labsiteCode + '/' + AppConstants.Blob.LabSite + '/' + AppConstants.Blob.LabsiteByPublicSynchronizer+'/' + infoType;
        }
        return this.http.get(this.appService.publicFileUrl + 'Files/downloadSpecific/' + urlParams );        
    }
}
