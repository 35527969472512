import { APP_BASE_HREF, isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { CookieComponent } from './features/cookie/cookie.component';
import { DisclaimerComponent } from './features/disclaimer/disclaimer.component';
import { FooterComponent } from './main/layout/footer/footer.component';
import { SliderComponent } from './main/layout/slider/slider.component';
import { ToolbarComponent } from './main/layout/toolbar/toolbar.component';
import { MsalConfigDynamicModule } from './msal-config-dynamic.module';
import { MaterialModule } from './shared/material.module';
import { SharedModule } from './shared/shared.module';
import { LandingPageComponent } from './features/landing-page/landing-page.component';

export function createTranslateLoader(httpClient: HttpClient, platformId: string) {
  const translateLoader = isPlatformBrowser(platformId) ? new MultiTranslateHttpLoader(httpClient, [
    { prefix: '../assets/i18n/combinedTranslations/', suffix: '.json?v=' + new Date().getTime()}
  ]) : new MultiTranslateHttpLoader(httpClient, [
  ]);
  return translateLoader;
}
//const subdomain: any = window.location.pathname;
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent, ToolbarComponent, SliderComponent, DisclaimerComponent, CookieComponent, LandingPageComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    MaterialModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    MatNativeDateModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, PLATFORM_ID]
      }
    }),
    HttpClientModule,
    MsalConfigDynamicModule.forRoot(),
    FlexLayoutModule.withConfig({ ssrObserveBreakpoints: ['xs', 'lt-md', 'gt-md'] })
  ],
  providers: [
    { provide: LOCALE_ID, useFactory: GetLocalLang, deps: [PLATFORM_ID] },
    { provide: APP_BASE_HREF, useFactory: GetLabSiteURL, deps: [PLATFORM_ID] }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

export function GetLocalLang(platformId: string) {
  if (isPlatformBrowser(platformId)) {
    return navigator.language.match(/en|fr|de|es|it|pl|ro/) ? navigator.language : 'en';
  }
}


export function GetLabSiteURL(platformId: string) {
  if (isPlatformBrowser(platformId)) {
    const subdomain: any = window.location.pathname;
    return subdomain.split('/')[1].indexOf('-')>=0 ? '/'+subdomain.split('/')[1] : '/'
  }
  
}
