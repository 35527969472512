import { Injectable } from '@angular/core';
import { ApplicationInsightsService } from './applicationInsights.service';

@Injectable({
    providedIn: 'root'
})

export class LoggingService {
    constructor(public applicationInsightsService: ApplicationInsightsService) {

    }

    logPageView(name?: string, url?: string) {            
        this.applicationInsightsService.logPageView(name, url);
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.applicationInsightsService.logEvent(name, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {        
        this.applicationInsightsService.logMetric(name, average, properties);        
    }

    logException(exception: Error, severityLevel?: number) {        
        this.applicationInsightsService.logException(exception, severityLevel);
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.applicationInsightsService.logTrace(message, properties);
    }
}