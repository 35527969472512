import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppSettingsService } from '../../core/core.module';
import { GlobalRepositoryService } from '../../core/repository/global-repository.service';
import { HelperService } from './helper.service';

@Injectable({
    providedIn: 'root'
})

export class ApplicationInsightsService {
    
    appInsights: ApplicationInsights;
    appInsightsConfig: any;
    constructor(public appSettingsService: AppSettingsService, public globalRepository: GlobalRepositoryService,
        public helperService: HelperService) {

        this.appInsightsConfig = this.appSettingsService.appInsightsConfig;
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this.appInsightsConfig.instrumentationKey,
                enableAutoRouteTracking: false, // option to log all route changes                
                disableExceptionTracking: true,
                disableTelemetry: !this.appInsightsConfig.logging,
                loggingLevelTelemetry: 0
            }
        });
        this.appInsights.loadAppInsights();
    }

    logPageView(name?: string, url?: string) { // option to call manually                
        this.appInsights.trackPageView({ name: name, uri: url });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {        
        this.appInsights.trackMetric({ name: name, average: average }, properties);        
    }

    logException(exception: Error, severityLevel?: number) {
        exception.name = 'Error: PublicWebsite';
        exception.message = 'DeviceID: ' + this.getDeviceId() + ' Labsite: ' + this.getLabsiteCode() + ' VolunteerCode: ' + 
        this.getVolunteerCode() + ' EmailID: ' + 
        this.getVolunteerEmail() + ' Exception: ' + exception.message;               
        this.appInsights.trackException({ error: exception, exception: exception, severityLevel: severityLevel,  });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        if (this.appInsightsConfig.tracing) {
            message = 'DeviceID: ' + this.getDeviceId() + ' Labsite: ' + this.getLabsiteCode() + ' VolunteerCode: ' + 
            this.getVolunteerCode() + ' EmailID: ' + 
            this.getVolunteerEmail() + ' Trace: ' + message;
            this.appInsights.trackTrace({ message: message }, properties);
        }
    }

    getLabsiteCode() {
        return this.globalRepository.labsiteCode === '' ? 'NA' : this.globalRepository.labsiteCode;
    }

    getVolunteerCode() {
        return this.globalRepository.volunteerCode === '' ? 'NA' : this.globalRepository.volunteerCode;
    }

    getVolunteerEmail() {
        return this.globalRepository.volunteerEmailId === '' ? 'NA' : this.globalRepository.volunteerEmailId;
    }

    getDeviceId() {
        return this.helperService.getDeviceId();
    }
}
