import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatDatePickerConfig } from "../../shared/matdatepicker/matdatepicker.model";
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Observable, Subject, Subscription } from "rxjs";
import { distinctUntilChanged } from 'rxjs/operators';
import { ScreenSizeValue } from "../models/screenSizeValue";
import { MatStepper } from '@angular/material/stepper';
import moment from 'moment';
import { Router } from "@angular/router";
import { GlobalRepositoryService } from "../../core/repository/global-repository.service";
import { AppConstants } from "../../shared/constants/app-constants";
import { isPlatformBrowser } from '@angular/common';
import * as uuid from 'uuid';



@Injectable({
    providedIn: 'root'
})
export class HelperService {

    mediaSubscription!: Subscription;
    screenSize = new Subject<string>();
    isBrowser: boolean;


    constructor(public translateService: TranslateService,
        public mediaObserver: MediaObserver, public router: Router,
        public globalRepository: GlobalRepositoryService,
        @Inject(PLATFORM_ID) private platformId: string

    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    public getMatDateConfig() {
        const matDateConfig = new MatDatePickerConfig();
        matDateConfig.TodayButtonLabel = this.translateService.instant('TodayButtonLabel');
        matDateConfig.TodayButtonTooltip = this.translateService.instant('TodayButtonTooltip');
        matDateConfig.DateButtonTooltip = this.translateService.instant('DateButtonTooltip');
        matDateConfig.ClearButtonTooltip = this.translateService.instant('ClearButtonTooltip');
        return matDateConfig;
    }

    public GetStepperProgress(stepCompleted: boolean, alreadyProgressedStep: number,
        selectedStep: number, progressEachStep: number, progressValue: number) {
        if (!stepCompleted) {
            progressValue = progressValue + progressEachStep;
            alreadyProgressedStep = selectedStep + 1;
            return { progressValue, alreadyProgressedStep };
        }
        return { progressValue, alreadyProgressedStep };
    }

    public MarkAllStepsCompleted(stepper: MatStepper) {
        const progressValue = 100;
        stepper?.steps?.forEach(step => step.completed = true);
        const alreadyProgressedStep = stepper?.steps?.length ?? 1;
        return { progressValue, alreadyProgressedStep };
    }

    public getDropDownArrayWithEmptyOptions(data: any) {
        const dropDown: any = [];
        dropDown.push({ id: '', displayText: '' });
        const ds = this.getLabelValueArrayFromObject(data);
        ds.forEach(function (value, i) {
            dropDown.push({ id: i, displayText: value.label });
        });
        return dropDown;
    }

    public getDropDownArrayTypesWithEmptyOptions(data: any) {
        const dropDown = [];
        dropDown.push({ id: '', displayText: '' });
        data.forEach(function (item: any) {
            dropDown.push({ id: item[Object.keys(item)[0]], displayText: item.value ? item.value : item.name });
        });
        return dropDown;
    }

    public getDropdownArrayWithFormattedTime(data: any) {
        const dropDown: any = [];
        const format = this.getLabsiteTimeFormat();
        dropDown.push({ id: '', displayText: '' });
        const ds = this.getLabelValueArrayFromObject(data);
        ds.forEach(function (value, i) {
            dropDown.push({ id: i, displayText: moment(value.label, 'HH:mm').format(format) });
        });
        return dropDown;
    }

    public getLabsiteTimeFormat(): string {
        let format: string = 'hh:mm A';
        if (this.globalRepository.labsiteDetails) {
            switch (this.globalRepository.labsiteDetails.timeFormatEnum) {
                case AppConstants.TimeFormatByLabsite.TwentyFourHourEnum:
                    format = AppConstants.TimeFormatByLabsite.TwentyFourHourFormat;
                    break;
                case AppConstants.TimeFormatByLabsite.AmOrPmEnum:
                    format = AppConstants.TimeFormatByLabsite.AmOrPmFormat;
                    break;
            }
        }

        return format;
    }

    public getLabelValueArrayFromObject(data: any) {
        const returnValue = [];
        if (data !== undefined && data !== null) {
            for (const property in data) {
                if (Object.prototype.hasOwnProperty.call(data, property) && property.indexOf('$') === -1) {
                    returnValue.push({
                        label: data[property],
                        value: property
                    });
                }
            }
        }
        return returnValue;
    }

    public getScreenSize(): Observable<string> {
        return this.screenSize.asObservable();
    }

    public GetMediaType() {
        const getAlias = (MediaChange: MediaChange[]) => {
            return MediaChange[0].mqAlias;
        };

        this.mediaSubscription = this.mediaObserver
            .asObservable()
            .pipe(
                distinctUntilChanged(
                    (x: MediaChange[], y: MediaChange[]) => getAlias(x) === getAlias(y)
                )
            )
            .subscribe((change) => {
                if (change.length > 0) {
                    this.screenSize.next(change[0].mqAlias);
                }
            });
    }

    public getScreenSizeValue(items: ScreenSizeValue[], size: string) {
        let result = '';
        if (items?.length > 0) {
            const item = items.find((x: ScreenSizeValue) => x.screenSize === size);
            if (item) {
                result = item.value;
            }
        }

        return result;
    }

    public sortLabelValueArray(a: any, b: any) {
        const reA = /[^a-zA-Z]/g;
        const reN = /[^0-9]/g;
        const aA = a.displayText.toLowerCase().replace(reA, '');
        const bA = b.displayText.toLowerCase().replace(reA, '');
        if (aA === bA) {
            const aN = parseInt(a.label.replace(reN, ''), 10);
            const bN = parseInt(b.label.replace(reN, ''), 10);
            return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
            return aA > bA ? 1 : -1;
        }
    }

    numericOnly(event: any) {
        const patt = /^([0-9.])$/;
        const result = patt.test(event.key);
        return result;
    }

    numericOnlyForHeight(event: any) {
        const patt = /^([0-9,.])$/;
        const result = patt.test(event.key);
        return result;
    }

    numericOnlyForHeightNoChars(event: any) {
        const patt = /^([0-9])$/;
        const result = patt.test(event.key);
        return result;
    }

    alphaNumericWithSpecialChars(event: any) {
        const patt = /^([0-9a-zA-Z-_ ;/])$/;
        const result = patt.test(event.key);
        return result;
    }

    positiveOnly(event: any) {
        const patt = /^([0-9])$/;
        const result = patt.test(event.key);
        return result;
    }

    isNegativeNumeric(event: any) {
        const patt = /^[0-9-]$/;
        const result = patt.test(event.key);
        return result;
    }

    alphaNumericSpaceForIBAN(event: any) {
        const patt = /^([0-9A-Z ])$/;
        const result = patt.test(event.key);
        return result;
    }
    alphaNumericeForBIC(event: any) {
        const patt = /^([0-9A-Z])$/;
        const result = patt.test(event.key);
        return result;
    }

    public GetParticipationStatusColor(statusEnum: string): string {
        let colorcode;
        switch (statusEnum) {
            case '0':
            case 'Applied': {
                colorcode = 'grey';
                break;
            }
            case '1':
            case 'Application Hold': {
                colorcode = 'orange';
                break;
            }
            case '2':
            case 'Waiting for Feedback': {
                colorcode = 'orange';
                break;
            }
            case '3':
            case 'Application Accepted': {
                colorcode = 'blue';
                break;
            }
            case '4':
            case 'Application Rejected': {
                colorcode = 'red';
                break;
            }
            case '5':
            case 'Included': {
                colorcode = 'green';
                break;
            }
            case '6':
            case 'Withdrawn': {
                colorcode = 'red';
                break;
            }
            case '7':
            case 'Pre-Study Visit': {
                colorcode = 'blue';
                break;
            }
            default: {
                colorcode = '';
                break;
            }
        }
        return colorcode;
    }

    public GetPaymentStatusColor(statusEnum: string): string {
        let colorcode;
        switch (statusEnum) {
            case '0':
            case 'Pending': {
                colorcode = 'grey';
                break;
            }
            case '1':
            case 'Payment Due':
            case 'Payment Due (Export)': {
                colorcode = 'blue';
                break;
            }
            case '2':
            case 'Paid': {
                colorcode = 'green';
                break;
            }
            case '3':
            case 'Payment Failed': {
                colorcode = 'orange';
                break;
            }
            case '4':
            case 'Cancelled': {
                colorcode = 'red';
                break;
            }
            default: {
                colorcode = '';
                break;
            }
        }
        return colorcode;
    }

    NavigateToLabsiteInformation(attribute: string) {
        const url = this.router.serializeUrl(
            // @ts-ignore
            this.router.createUrlTree([this.router.location._basePath.split('/')[1] + '/labsiteInformation/' + attribute])
        );

        if (this.isBrowser) {
            window.open(url, '_blank');
        }


    }

    preventPreSpace(event: any) {
        if (event.target.selectionStart === 0 && event.code === 'Space') {
            event.preventDefault();
        }
    }

    getDeviceId() {
        let deviceId = localStorage.getItem('deviceId')
        if (!deviceId) {
            deviceId = uuid.v4();
            localStorage.setItem('deviceId', deviceId)
        }
        return deviceId
    }

    getTextFromEnumType(id: any, list: any): string {
        let text = '';
        if (id) {
            text = list.find((item: any) => item.id === id)?.displayText;
        }
        return text;
    }

    public getTranslatedValue(enumType: string, enumValue: string) {
        return this.translateService.instant(enumType + '.' + enumValue);
    }
}
