import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ColumnListComponent } from './column-list/column-list.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { CardColumnHeaderComponent } from './cardColumnHeader/cardColumnHeader.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { MatDatePickerComponent } from './matdatepicker/matdatepicker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RichTextEditorViewerModalComponent } from './rich-text-editor-viewer-modal/rich-text-editor-viewer-modal.component';
import { DetailRowComponent } from './detailRow/detailRow.component';
import { StatusIndicatorComponent } from './statusIndicator/statusIndicator.component';
import { DetailRowWithIndicatorComponent } from './detailRowWithIndicator/detailRowWithIndicator.component';
import { GlobalErrorComponent } from './global-error/global-error.component';
import { TranslateOptionsPipe } from '../shared/translate-options/translate-options.pipe';
import { SplitPipe } from '../shared/split/split.pipe';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  declarations: [ColumnListComponent, CardColumnHeaderComponent, ConfirmPopupComponent, MatDatePickerComponent,
    RichTextEditorViewerModalComponent, DetailRowComponent, DetailRowWithIndicatorComponent, StatusIndicatorComponent,
    GlobalErrorComponent, TranslateOptionsPipe, SplitPipe, SignaturePadComponent],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    ColumnListComponent,
    CardColumnHeaderComponent,
    RichTextEditorViewerModalComponent,
    ConfirmPopupComponent,
    MatDatePickerComponent,
    NgSelectModule,
    DetailRowComponent,
    DetailRowWithIndicatorComponent,
    StatusIndicatorComponent,
    GlobalErrorComponent,
    TranslateOptionsPipe,
    SplitPipe,
    SignaturePadComponent
  ]
})
export class SharedModule {
}
