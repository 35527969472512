import { isPlatformBrowser, PlatformLocation } from '@angular/common';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

const CONFIGURATION_FILE = 'config.json?v=' + new Date().getTime();

// const CONFIGURATION_FILE = 'currentConfigs.js';

@Injectable({ providedIn: 'root' })

/** Service to get Configuartion Details */
export class ConfigService {

  /* #region Fields */
  private config: any;
  http: HttpClient;
  isBrowser: any;
  /* #endregion */

  constructor(httpHandler: HttpBackend, @Inject(PLATFORM_ID) platformId: string, public platformLocation: PlatformLocation) {
    this.http = new HttpClient(httpHandler);
    this.isBrowser = isPlatformBrowser(platformId);
  }

  /* #region Public Methods */
  /** Method to get data from config based on the key */
  public getConfig<T>(key: string): T {
    return this.config[key];
  }

  /** Method to load configuration -  used on app initializer */
  public getSettings(): Promise<any> {
    const promise = this.http.get(!this.isBrowser ? "http://" +this.platformLocation.hostname + ":" + this.platformLocation.port + "/" + CONFIGURATION_FILE : CONFIGURATION_FILE).toPromise().then((settings: any) => {

  //  const promise = this.http.get(!this.isBrowser ? "https://localhost:8080/" + CONFIGURATION_FILE : CONFIGURATION_FILE).toPromise().then((settings: any) => {
      this.config = settings;
      if (this.isBrowser) {
        const isSigninFlow = window.localStorage.getItem('isSigninFlow');
        if (isSigninFlow == 'true') {
          this.config.auth.authority = this.config.auth.signinAuthority;
        } else {
          this.config.auth.authority = this.config.auth.signupAuthority;
        }
      }
      return this.config;
    },()=>{
    });
    return promise;
  }

  public getSettingsForKey(key?: string | Array<string>) {
    if (!key || (Array.isArray(key) && !key[0])) {
      return this.config;
    }

    if (!Array.isArray(key)) {
      key = key.split('.');
    }

    const result = key.reduce((account: any, current: string) => account && account[current], this.config);

    return result;
  }
  /* #endregion */
}
