import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettingsService } from '../../../core/core.module';
import { AppConstants } from '../../../shared/constants/app-constants';

@Injectable({
    providedIn: 'root'
})
export class FooterService {    

    constructor(public http: HttpClient, public appService: AppSettingsService) { }

    public getFooterInformation(labsiteCode: string): Observable<any> {       
        return this.http.get(this.appService.baseApiUrlForCore + 'labsites/GetLabsiteSocialMediaLinks/' + labsiteCode);
    }

    public getFooterInformationFromBlob(labsiteCode: string, infoType:string): Observable<any> {        
        let urlParams: string =   labsiteCode + '/' + AppConstants.Blob.LabSite + '/' + AppConstants.Blob.LabSite+ '/' + infoType
        if(this.appService.isPublicWebSiteSyncronizerEnabled){
            urlParams = labsiteCode + '/' + AppConstants.Blob.LabSite + '/' + AppConstants.Blob.LabsiteByPublicSynchronizer+'/' + infoType;
        }
        return this.http.get(this.appService.publicFileUrl + 'Files/downloadSpecific/' + urlParams);        
    }
}