<div>
  <div fxFlex="98">
    <mat-label id="addVisit" class="header-text"><b>{{ 'Disclaimer.DisclaimerHeader' | translate}}</b></mat-label>
  </div>
  <div fxFlex="2">
    <span class="icon-close" color="accent" (click)="Dismiss()"></span>
  </div>
</div>



<div fxLayout="column">
  <div class="row contentPosition">
    <div fxFlex="100">
      <div [innerHtml]="disclaimersIntroductionText" ></div>
    </div>
    <div fxFlex="100">
      {{ 'Disclaimer.HeaderText' | translate}}
    </div>
  </div>
  <div [ngClass.xs]="{'scrollDisclaimer': true}">
  <div class="row contentPosition">
    <div fxFlex="2">
      <mat-checkbox  [(ngModel)]="disclaimerData.termsOfUse" color="primary">
      </mat-checkbox>
    </div>
    <div fxFlex="90" class="contentTextPosition">
      {{ 'Disclaimer.TermsOfUseText' | translate}}
      <u><a class="linktextColor" (click)="NavigateToLabsiteInformation('TermsOfUse')"> {{'Disclaimer.SeeDetailsText' |
          translate }}</a></u><br><label></label>
    </div>
  </div>

  <div class="row contentPosition">
    <div fxFlex="2">
      <mat-checkbox  [(ngModel)]="disclaimerData.dataPrivacy" color="primary">
      </mat-checkbox>
    </div>
    <div fxFlex="90" class="contentTextPosition">
      {{ 'Disclaimer.DataPrivacyText' | translate}}
      <u><a class="linktextColor" (click)="NavigateToLabsiteInformation('DataPrivacyPage')">
          {{'Disclaimer.SeeDetailsText' |
          translate }}</a></u><br>
      {{ 'Disclaimer.DataPrivacyAuthorizeText' | translate}}{{labsiteName}}
      {{ 'Disclaimer.DataPrivacyAdditionalText' | translate}}
    </div>
  </div>


  <div class="row contentPosition">
    <div fxFlex="2">
      <mat-checkbox  [(ngModel)]="disclaimerData.volunteerRules" color="primary">  
      </mat-checkbox>
    </div>
    <div fxFlex="90" class="contentTextPosition">
      {{ 'Disclaimer.VolunteerRulesText' | translate}}
      <u><a class="linktextColor" (click)="NavigateToLabsiteInformation('VolunteerRulesPage')">
          {{'Disclaimer.SeeDetailsText' |
          translate }}</a></u>
    </div>
  </div>
<div>
  <button mat-raised-button class="nav-btn navButton pull-left" color="primary" (click)="Confirm()" id="confirmButton"
 [disabled]="!CanConfirm()">{{
    'Disclaimer.ConfirmButtonText' | translate}}</button>
</div>
</div>
</div>