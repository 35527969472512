import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs/internal/Observable';
import { ConfirmPopupComponent } from '../../shared/confirm-popup/confirm-popup.component';
import { isPlatformBrowser } from '@angular/common';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public displayTime = 10000;
  isBrowser: boolean;

  constructor(private toastr: ToastrService,
    private translateService: TranslateService,
    private dialog: MatDialog, @Inject(PLATFORM_ID) platformId: string,
    private loggingService: LoggingService) {
    this.isBrowser = isPlatformBrowser(platformId);

  }

  /* #region Public Methods */
  /** This method is being used to show Confirmation Pop up */
  public confirm(message: string, title: string,
    confirmLabel: string = 'Common.Button.Yes', cancelLabel: string = 'Common.Button.No'): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '535px',
      minHeight: '220px',
      maxHeight: '320px',
      data: {
        message: message, title: title,
        confirmLable: confirmLabel, cancelLabel: cancelLabel
      },
      autoFocus: false,
      disableClose: true
    });
    return dialogRef.afterClosed();
  }


  /** This method is used to show the confirmation popup for unsaved changes */
  public doConfirmationForUnsavedChanges(callBack: Function, callBackParams: any = null) {
    if (!this.isBrowser) return;
    this.doConfirmation(callBack, callBackParams, this.translateService.instant('UnSavedChanges'),
      this.translateService.instant('Continue'), this.translateService.instant('Yes'),
      this.translateService.instant('No'));
  }

  /** This method is used to show confirmation popup */
  public doConfirmation(callBack: Function, callBackParams: any = null, message: string = '',
    title: string = '', confirmLabel: string = '', cancelLabel: string = '') {
    if (!this.isBrowser) return;
    this.confirm(message, title, confirmLabel, cancelLabel).subscribe((actionResult: boolean) => {
      if (callBackParams) {
        callBack(actionResult, callBackParams);
      } else {
        callBack(actionResult);
      }
    });
  }

  /** This method is being used to display a Warning toast messsage */
  public warning(message: string, displayTime = this.displayTime, tapToDismiss = true, title: string = ''): void {
    if (!this.isBrowser) return;
    this.toastr.show(message, title, this.getConfig('ngx-toastr border-w custom-toast-warning', displayTime, tapToDismiss));
  }

  /** This method is being used to display a Success toast messsage */
  public success(message: string, displayTime = this.displayTime, tapToDismiss = true, title: string = ''): void {
    if (!this.isBrowser) return;
    this.toastr.show(message, title, this.getConfig('ngx-toastr border-s custom-toast-success', displayTime, tapToDismiss));
  }

  /** This method is being used to display a Alert toast messsage with AI Logging*/
  public alert(message: string, aiLogging = false, displayTime = this.displayTime, tapToDismiss = true, title: string = '', exceptionMessage?: string): void {
    if (!this.isBrowser) return;
    if (aiLogging)
      this.loggingService.logException(Error(message + exceptionMessage));
    this.toastr.show(message, title, this.getConfig('ngx-toastr border-a custom-toast-error', displayTime, tapToDismiss));
  }

  /** This method is being used to clear toast messsage */
  public clear(): void {
    this.toastr.clear();
  }
  /* #endregion */

  /* #region Private Methods */
  /** Method to customize / set toast Config */
  private getConfig(className: string, displayTime: number, tapToDismiss: boolean): any {
    return {
      closeButton: true,
      toastClass: className,
      messageClass: 'custom-message',
      timeOut: displayTime,
      tapToDismiss: tapToDismiss
    };
  }
  /* #endregion */

}
