import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()

/** Service to Intercept Http request or response */
export class HttpInterceptorService implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (request.method === 'GET') {
      request = this.SetCacheHeaders(request);
    }
    if (!request.withCredentials) {
      const labsiteCode = localStorage.getItem('labSiteCode')
      if (labsiteCode) {
        request = request.clone({
          headers: request.headers
            .set('LabSiteCode', labsiteCode)
        });
      }
    }
    return next.handle(request);
  }

  SetCacheHeaders(request: HttpRequest<any>) {
    request = request.clone({
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      })
    });
    return request;
  }
}
