import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppSettingsService } from './core/core.module';
import { AuthenticationResult, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { ApplicationService } from './app.service';
import { GlobalRepositoryService } from './core/repository/global-repository.service';
import { Router } from '@angular/router';
import { VolunteerService } from './features/volunteer/volunteer.service';
import { SpinnerService } from './core/spinner/spinner.service';
import { MessageService } from './main/services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { LoggingService } from './main/services/logging.service';

@Injectable({
  providedIn: 'root'
})
export class UserLoginService {

  isBrowser: boolean;
  policyType: string;

  constructor(@Inject(PLATFORM_ID) platformId: string, private http: HttpClient, public appService: ApplicationService, @Inject(MSAL_GUARD_CONFIG) public msalGuardConfig: MsalGuardConfiguration,
    public authService: MsalService, public globalRepositoryService: GlobalRepositoryService, public appSettingsService: AppSettingsService,
    public router: Router, public volunteerService: VolunteerService, public spinner: SpinnerService, public messageService: MessageService,
    public translateService: TranslateService, private appSettingService: AppSettingsService, public loggingService: LoggingService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.policyType = this.appSettingService.msalConfig.policyType;
  }



  public async getUserDetails() {

    this.spinner.show(true);

    const isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;

    if (isUserLoggedIn) {
      const accountId = (this.authService.instance.getAllAccounts())[0]?.localAccountId;
      const userClaim = (this.authService.instance.getAllAccounts())[0];
      const idTokenClaims = userClaim.idTokenClaims;
      this.globalRepositoryService.setUserAccountId(userClaim.localAccountId);

      if (this.policyType === "Custom") {
        this.globalRepositoryService.setVolunteerEmailId((idTokenClaims as any)?.email);
      } else {
        this.globalRepositoryService.setVolunteerEmailId(userClaim.username);
      }

      this.loggingService.logTrace('PolicyType: ' + this.policyType + ' UserClaim: ' + JSON.stringify(userClaim)
        + ' IdTokenClaims: ' + JSON.stringify(idTokenClaims));

      if (!!idTokenClaims && (idTokenClaims as any)?.newUser) {
        this.populateAddVolunteerSlider();
      } else {
        this.globalRepositoryService.setUserAccountId(accountId);
        // @ts-ignore
        this.loadLabsiteDetails(this.router.location._basePath.split('/')[1]);
      }
      this.spinner.show(false);
    }

    if (this.isBrowser && window.localStorage.getItem('isSignoutFlow') == 'true') {
      window.localStorage.setItem('isSignoutFlow', 'false');
      const labSiteURLCode = window.localStorage.getItem('labSiteURLCode');
      location.replace('/' + labSiteURLCode + '/home');
    }
  }

  async populateAddVolunteerSlider() {
    if (this.isBrowser && !this.globalRepositoryService.getLabsiteCode()) {
      const LabsiteCode = window.localStorage.getItem('labSiteCode');
      const LabsiteDetails: any = window.localStorage.getItem('labSiteDetails');
      if (LabsiteCode) {
        this.globalRepositoryService.setLabsiteCode(LabsiteCode);
        this.globalRepositoryService.setLabsiteDetails(JSON.parse(LabsiteDetails));
      } else {
        // get labsite data if labsitecode not present in localstorage
        // @ts-ignore
        const labSiteURLCode = this.router.location._basePath.split('/');
        const url = `${this.appSettingService.baseApiUrlForCore}labsites/GetLabSiteByURLConfigs/` + labSiteURLCode[1];
        const result: any = await this.http.get(url).toPromise();
        this.globalRepositoryService.setLabsiteDetails(result);
        this.globalRepositoryService.setLabsiteOnlineFormConfigurations(result.labsiteOnlineFormConfigurations);
        this.globalRepositoryService.setHeightWeightUnitEnum(result.heightWeightUnitEnum);
        this.globalRepositoryService.setLabsiteCode(result?.code);
      }
    }
    this.spinner.show(false);
    this.volunteerService.AddVolunteerSlider(true);
  }

  async loadLabsiteDetails(labSiteURLCode: string) {
    if (labSiteURLCode) {
      const url = `${this.appSettingsService.baseApiUrlForCore}labsites/GetLabSiteByURLConfigs/` + labSiteURLCode;
      try {
        this.spinner.show(true);
        const result: any = await this.http.get(url).toPromise();
        if (result && !result.hasError) {
          this.globalRepositoryService.setLabsiteDetails(result);
          this.globalRepositoryService.setLabsiteCode(result.code);
          this.globalRepositoryService.setLabsiteOnlineFormConfigurations(result.labsiteOnlineFormConfigurations);
          this.globalRepositoryService.setHeightWeightUnitEnum(result.heightWeightUnitEnum);
          await this.getUserInformation(this.globalRepositoryService.getUserAccountId());
          this.spinner.show(false);
        }
      } catch (exception: any) {
        this.spinner.show(false);
        this.messageService.alert(this.translateService.instant('ErrorWhileLoadingLabSiteDetails'), true, 10000, true, '', exception.message);
      }
    }
  }


  getUserInformation(accountId: string): any {
    this.spinner.show(true);
    this.appService.getUserInformation(accountId, this.globalRepositoryService.getLabsiteCode()).subscribe(
      (user: any) => {
        this.spinner.show(false);
        if (user.hasError && user.isInvalidUser) {
          this.messageService.alert(this.translateService.instant('MyAccount.InvalidUserRegistered') + ' ' + user.labSiteCode + ' ' +
            this.translateService.instant('MyAccount.InvalidAutherization') + ' ' + this.globalRepositoryService.getLabsiteCode() + ' ' +
            this.translateService.instant('MyAccount.InvalidUserContactAdmin'));
          this.spinner.show(false);
          setTimeout(() => {
            this.onLogoutClick();
          }, 4000);

        } else if (user?.volunteerCode) {
          const volunteerCode: string = user?.volunteerCode;
          const volunteerId: string = user?.volunteerId;
          this.globalRepositoryService.setVolunteerCode(volunteerCode);
          this.globalRepositoryService.setVolunteerId(volunteerId);
          this.globalRepositoryService.isVolunteerCodeModified.next(true);
        } else {
          this.volunteerService.AddVolunteerSlider(false);
        }
      }, () => {
        this.messageService.alert(this.translateService.instant('MyAccount.ErrorWhileFetchingUserInformation'), true);
        this.spinner.show(false);
      });
  }


  onSignUpClick() {

    const signupFlowRequest = {
      scopes: ["openid"],
      authority: this.appSettingsService.msalConfig.signupAuthority
    };
    this.loggingService.logTrace('Request: User SignUp - ' + JSON.stringify(signupFlowRequest));
    this.login(signupFlowRequest);

  }



  onLoginClick() {
    const signinFlowRequest = {
      scopes: ["openid"],
      authority: this.appSettingsService.msalConfig.signinAuthority
    };
    this.loggingService.logTrace('Request: User SignIn - ' + JSON.stringify(signinFlowRequest));
    this.login(signinFlowRequest);
    if (this.isBrowser) {
      localStorage.setItem('isSigninFlow', 'true');
    }
  }


  public login(userFlowRequest: RedirectRequest | PopupRequest | undefined) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  onLogoutClick() {
    this.loggingService.logTrace('Request: User Logout');
    this.authService.logout();
    this.globalRepositoryService.removeVolunteerCode();
    this.globalRepositoryService.removeLabsiteOnlineFormConfigurations();
    if (this.isBrowser) {
      localStorage.setItem('isSignoutFlow', 'true');
      localStorage.setItem('isSigninFlow', 'false');
    }
  }
}
