<div *ngIf="isLandingPageLoaded">
  <div class="content-container">
      <div class="container-cover">
        <div class="welcome-container">
          {{ 'LandingPage.WelcomeText' | translate }}
        </div>
        <div class ="know-more"> {{ 'LandingPage.TextBeforeLink' | translate}} <a href = "https://www.eurofins.com/cosmetics/about-us" style="color: orange;">{{ 'LandingPage.ClickHereText' | translate}}</a> </div> 
      </div>
  </div>

  <div class="body-Container">
    <div class="title"> {{ 'LandingPage.VolunteerText' | translate}} </div>
      <div fxLayout="row wrap"  fxLayoutGap="10px">
      <div fxLayout="column"  fxFlex.md="32" fxFlex="32" fxFlex.sm="40"
      fxFlex.xs="100" *ngFor="let landingPageImage of landingPageImagesData" class="image-wrapper" (click)="redirectToURL(landingPageImage.labsiteURL)" >
        <img [src]="landingPageImage.imagePath" class="image">
        <div class="title-container selection-title">
          <mat-card-content class="image-text">{{ landingPageImage.labsitename }}</mat-card-content>
          <div class="arrow"> &#62;</div>
        </div>
      </div>
    </div>
  </div>
</div>

