<div class="error-container">
  <div class="error-container-details">
    <div>
      <div class="{{ errorIcon }} icon-error icon-inner-circle"></div>
    </div>
    <div style="margin-left: 2%">

      <ng-container *ngIf="errorStatusCode === '401' else forbidden">
      
      </ng-container>

      <ng-template #forbidden>
        <ng-container *ngIf="errorStatusCode === '403' else notfound">
        </ng-container>
      </ng-template>

      <ng-template #notfound>
        <ng-container *ngIf="errorStatusCode === '500' else internal">
        </ng-container>
      </ng-template>

      <ng-template #internal>
        <p >{{'ErrorPage.ErrorPagePageNotFoundHeader' | translate}}</p>
        <p>{{ errorStatusCode }}</p>
        <p >{{'ErrorPage.ErrorPagePageNotFoundText' | translate}}
        </p>
      </ng-template>

      <a *ngIf="isNavigateBackRequired" (click)="navigateBack()"><span class="icon-back mr-10"></span>{{'ErrorPage.GoBackText' | translate}}</a>
    </div>
      </div>
      </div>
